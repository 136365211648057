<template>
  <div class="main-content-tab">
    <hcc-card class="sidebar-list" bodyClass="siderbar-list__body">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.component"
          :class="{ 'is-active': activeTab === tab.component }"
          @click="setCurrentTab(tab)"
        >
          <a
            variant="transparent"
            size="md"
            :aria-selected="activeTab === tab.component"
          >
            <div class="sidebar-list__link">
              <div>
                <component :is="tab.icon" />
                {{ tab.title }}
              </div>
              <hcc-badge
                :text="$t('campaigns.data-bank.templates.only-whatsapp')"
                size="xs"
                color="whatsapp"
                fill
                rounded
                class="sidebar-list__link-badge"
                v-if="tab.title === 'Templates'"
              />
            </div>
          </a>
        </li>
      </ul>
    </hcc-card>
    <div class="tab-container">
      <slot />
    </div>
  </div>
</template>

<script>
import HccBadge from '@/components/shared/HccBadge/index.vue';

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
  },
  components: {
    HccCard: () => import('@/components/shared/HccCard/index.vue'),
    HccBadge,
  },
  methods: {
    setCurrentTab(tab) {
      this.$emit('change', tab);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/components/settings/_vertical-tabs";
</style>
